import GeoCodingRepository from "./wp-seo-local-geocoding-repository.js";

/**
 *
 */
class StoreLocator {
	/**
	 * Constructor for the StoreLocator JS class.
	 * Here we assign fields to class constants and bind methods.
	 */
	constructor() {
		this.searchForm = document.querySelector( "#wpseo-storelocator-form" );
		this.searchInput = document.querySelector( "#wpseo-sl-search" );
		this.locationDetectionButton = document.querySelector( ".wpseo_use_current_location" );
		this.locationDetectionButtonImg = document.querySelector( ".wpseo_use_current_location img" );
		this.latField = document.querySelector( "#wpseo-sl-lat" );
		this.lngField = document.querySelector( "#wpseo-sl-lng" );

		this.latLng = "";

		this.locationDetection = this.locationDetection.bind( this );
		this.getLatLng = this.getLatLng.bind( this );
		this.maybeGeoCodeAddress = this.maybeGeoCodeAddress.bind( this );
		this.handleSubmitForm = this.handleSubmitForm.bind( this );
	}

	/**
	 * Add event listeners to fire a function upon specified events.
	 */
	addEventListeners() {
		document.addEventListener( "click", this.locationDetection );
		document.addEventListener( "change", this.maybeGeoCodeAddress );
		document.addEventListener( "submit", this.handleSubmitForm );
	}

	/**
	 * Auto detect location based on browser information.
	 *
	 * @param e The event passed by the event listener.
	 */
	async locationDetection( e ) {
		// Check both the button and the image in it for the click event.
		if ( e.target === this.locationDetectionButton || e.target === this.locationDetectionButtonImg ) {
			const targetInputId = this.locationDetectionButton.dataset.target;
			const targetInputField = document.querySelector( "#" + targetInputId );

			// First try to get the lat and lng from the browser.
			try {
				this.latLng = await this.getLatLng();

				this.latField.value = this.latLng.lat;
				this.lngField.value = this.latLng.lng;
			} catch ( error ) {
				console.log( error );
			}

			// Continue the geocoding if the requested lat/lng from the browser did not result in an error.
			if ( false === this.latLng instanceof Error ) {
				try {
					const address = await GeoCodingRepository.geoCodeAddress( { "location": this.latLng } );
					targetInputField.value = address[ 0 ].formatted_address;
				} catch ( error ) {
					console.log( error );
				}
			}
		}
	}

	/**
	 * Get the Lat and Lng position from the browser.
	 *
	 * @returns {Promise<*>}
	 */
	async getLatLng() {
		return new Promise( ( resolve, reject ) => {
			navigator.geolocation.getCurrentPosition( ( position ) => {
				return resolve( {
					lat: parseFloat( position.coords.latitude ),
					lng: parseFloat( position.coords.longitude ),
				} );
			}, function( error ) {
				return reject( new Error( "Location detection unsuccesfull" ) );
			} );
		} );
	}

	/**
	 * Determine if an address should be geocoded. If so: do so.
	 *
	 * @param e The event passed by the event listener.
	 *
	 * @returns {Promise<void>}
	 */
	async maybeGeoCodeAddress( e ) {
		if ( e.target === this.searchInput ) {
			try {
				const results = await GeoCodingRepository.geoCodeAddress( { address: this.searchInput.value } );

				this.latField.value = results[ 0 ].geometry.location.lat();
				this.lngField.value = results[ 0 ].geometry.location.lng();

			} catch ( error ) {

			}
		}
	}

	/**
	 * Catch the submit event and check wheter possibly the lat/lng data has to be calculated.
	 *
	 * @param e The event passed by the event listener.
	 *
	 * @returns {Promise<void>}
	 */
	async handleSubmitForm( e ) {
		if ( e.target === this.searchForm ) {
			e.preventDefault();

			if ( this.latField.value === "" || this.lngField.value === "" ) {
				document.removeEventListener( "submit", this.handleSubmitForm );
				const promise = new Promise( async (resolve, reject) => {
					const results = await GeoCodingRepository.geoCodeAddress( { address: this.searchInput.value } );

					this.latField.value = results[ 0 ].geometry.location.lat();
					this.lngField.value = results[ 0 ].geometry.location.lng();

					if ( this.latField.value !== "" && this.lngField.value !== "" ) {
						resolve( 'success' );
					}
				});

				const result = await promise;

				if( result === 'success' ) {
					this.searchForm.submit();
				}
			} else {
				this.searchForm.submit();
			}
		}
	}
}

const storeLocatorInstance = new StoreLocator();

storeLocatorInstance.addEventListeners();