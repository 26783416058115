/**
 * wpseoLocalGeocodingRepository class for geocoding addresses.
 */
export default class GeocodingRepository {
	/**
	 * Geocode the address based using the Google maps JavaScript geocoding API
	 *
	 * @var object An object containing either { "address": <address as a string> } or { "location": <the LatLng coordinates>}
	 */
	static async geoCodeAddress( location ) {
		const geocoder = new google.maps.Geocoder();

		if ( typeof location === "object" ) {
			return new Promise( ( resolve, reject ) => {
				geocoder.geocode( location, ( results, status ) => {
					if ( status === "OK" ) {
						return resolve( results );
					}

					return reject( status );
				} );
			} );
		}

		throw new Error( "Location should be an object" );
	}
}